<template>
    <component
        :is="to ? 'a' : 'button'"
        @click.stop.prevent="clickButton"
        :href="to"
        :type="type"
        class="button"
        :class="{ icon, white, disabled, label, light }"
        :style="{
            '--button-color': background,
            '--color': color,
        }"
        :disabled="disabled"
        ><slot></slot
    ></component>
</template>
<script>
export default {
    props: {
        white: Boolean,
        light: Boolean,
        icon: Boolean,
        disabled: Boolean,
        label: Boolean,
        to: {
            required: false,
            type: String || Object,
        },
        background: {
            type: String,
            default: "#1a5cff",
        },
        color: {
            type: String,
            default: "white",
        },
        type: {
            type: String,
            default: "button",
        },
    },
    methods: {
        clickButton(event) {
            if (typeof this.to === "string" && this.to.startsWith("https://")) {
                return window.open(this.to, "_blank")
            }
            event.preventDefault()
            if (this.to) {
                this.$router.push(this.to).catch(console.error)
            }
            return false
        },
    },
}
</script>
<style lang="scss" scoped>
.button {
    background: var(--button-color);
    color: var(--color);
    text-decoration: none;

    &:first-child {
        margin-left: 0;
    }
    padding: 4px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    user-select: none;

    &:hover {
        filter: brightness(116%) contrast(1.12);
        box-shadow: 0 2px 5px -2px #000000;
        &.light {
            filter: brightness(90%) contrast(1.12);
        }
    }

    &:active {
        transition: all 0 ease;
        box-shadow: 0 2px 5px -4px #000000;
        transform: translateY(0);
    }
    &.icon {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 0;
    }

    &.default {
        min-height: 36px;
    }

    &.medium {
        padding: 4px 12px;
    }

    &.small {
        padding: 4px 8px;
    }

    &.white {
        background: transparent;
        box-shadow: none;
    }

    &.inline-text {
        box-shadow: none;
        background: transparent;
        margin: 0;
        display: inline;
        color: var(--color);
        padding: 0;
        transform: translateY(0);
    }

    &.block {
        width: 100%;
    }

    &.border {
        border: 3px solid white;
    }

    &.thin-border {
        border: 1px solid white;
    }

    &.disabled {
        filter: saturate(0.8);
        opacity: 0.5;
        cursor: not-allowed;
        transform: none;
        box-shadow: none;
    }

    &.label {
        pointer-events: none;
    }
}
</style>
