import { createStore } from 'vuex'
import { storage } from '@/firebase'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { getFirestore, addDoc, setDoc, collection, doc } from 'firebase/firestore'

const db = getFirestore()

export default createStore({
    state: {
        recorder: {},
        uploadingInfo: {},
        transcript: []
    },
    mutations: {
        setRecorder (state, recorder) {
            state.recorder = recorder
        },
        uploadingInfo (state, info) {
            state.uploadingInfo = info
        },
        setTranscript (state, transcript) {
            state.transcript = transcript
        }
    },
    actions: {
        setRecorder ({ commit }, recorder) {
            commit('setRecorder', recorder)
            commit('uploadingInfo', {})
        },
        async upload ({ commit, state }, title) {
            const uid = getAuth().currentUser.uid
            const blob = state.recorder.getBlob()

            try {
                const docRef = await addDoc(collection(db, 'users', uid, 'videos'), { title })
                const uploadTask = uploadBytesResumable(ref(storage, `${uid}/${docRef.id}.mp4`), blob)

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        )
                        let status = ''
                        if (snapshot.state === 'paused') status = `paused at ${progress}%`
                        if (snapshot.state === 'running') status = `${progress}% done`
                        commit('uploadingInfo', { progress, status })
                    },
                    (error) => {
                        commit('uploadingInfo', { status: 'Uploading error' })
                        console.log('error uploading', error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref)
                            .then((downloadURL) => {
                                commit('uploadingInfo', {
                                    progress: 100,
                                    status: 'Done'
                                })

                                setDoc(doc(db, 'users', uid, 'videos', docRef.id), { downloadURL }, { merge: true })
                                console.log('File available at', downloadURL)
                            })
                    }
                )
            } catch (e) {
                console.error('Error adding document: ', e)
            }
        },
        setTranscript ({ commit }, transcript) {
            commit('setTranscript', transcript)
        }
    },
    modules: {
    }
})
