// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'

import { firebaseConfig } from './config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
export const storage = getStorage(app)

// if (window.location.hostname === 'localhost') {
//     console.log('localhost detected!')
//     app.firestore().settings({
//         host: 'localhost:8080',
//         ssl: false
//     })
//     app.auth().useEmulator('http://localhost:9099/')
//     app.functions().useFunctionsEmulator('http://localhost:5001')
// }
