<template>
    <span class="date" ref="date" :datetime="date">date</span>
</template>
<script>
import * as timeago from 'timeago.js'
export default {
    props: {
        date: String
    },
    mounted () {
        timeago.render(this.$refs.date)
    }
}
</script>
