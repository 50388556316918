<template>
    <div class="close"></div>
</template>
<style lang="scss" scoped>
.close {
    position: relative;
    width: 28px;
    height: 28px;
    /* box-shadow: 0 2px 4px -1px black; */
    border-radius: 50%;
    /* background: #e82c2c; */
    cursor: pointer;
}
.close:hover {
    /* background: #d92626; */
    filter: brightness(65%) contrast(1.2);
}
.close {
    &:before,
    &:after {
        position: absolute;
        top: 5px;
        left: 13px;
        content: " ";
        height: 18px;
        width: 2px;
        background-color: black;
    }

    &.white:before,
    &.white:after {
        background-color: white;
    }
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
</style>
