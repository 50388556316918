<template>
    <div v-if="videos" class="body">
        <div class="_header">My videos</div>
        <div class="videos">
            <a
                class="video_item"
                v-for="video in videos"
                :key="video.url"
                :href="video.url"
                target="_blank"
            >
                <div class="title">{{ video.name }}</div>
                <div class="modified">
                    <wTimeago :date="video.metadata.updated" />
                </div>
                <div class="meta">
                    <div class="size">
                        {{ convertBytes(video.metadata.size) }}
                    </div>
                    <div class="type">{{ video.metadata.contentType }}</div>
                </div>
            </a>
        </div>
    </div>
    <div v-else class="loading">Looking for saved videos.</div>
</template>

<script>
import { storage } from "@/firebase"
import { getFirestore, doc, getDoc } from "firebase/firestore"

import {
    getAuth,
    signOut,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
} from "firebase/auth"
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage"

import wTimeago from "@/components/wTimeago"
import loginIcon from "@iconify-icons/ic/round-login"
import logoutIcon from "@iconify-icons/ic/round-logout"
import videoLibraryIcon from "@iconify-icons/ic/round-video-library"
import outlineVideoCall from "@iconify-icons/ic/outline-video-call"

const db = getFirestore()
const auth = getAuth()

export default {
    name: "Videos",
    components: {
        wTimeago,
    },
    data() {
        return {
            icons: {
                loginIcon,
                logoutIcon,
                videoLibraryIcon,
                outlineVideoCall,
            },
            videos: null,
            loggedIn: false,
        }
    },
    mounted() {
        onAuthStateChanged(auth, (user) => {
            this.loggedIn = user
            if (this.loggedIn) this.loadVideos()
        })
    },
    methods: {
        convertBytes(bytes) {
            bytes = Number(bytes)
            if (!bytes) return "n/a"
            const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
            return !i
                ? `${bytes} ${sizes[i]}`
                : `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
        },
        async loadVideos() {
            if (!this.loggedIn) return
            listAll(ref(storage, this.loggedIn.uid))
                .then((videos) =>
                    Promise.all(
                        videos.items.map(async (item) => {
                            const _ref = ref(storage, item.fullPath)
                            const [url, metadata, docSnap] = await Promise.all([
                                getDownloadURL(_ref),
                                getMetadata(_ref),
                                getDoc(
                                    doc(
                                        db,
                                        "users",
                                        this.loggedIn.uid,
                                        "videos",
                                        item.name
                                    )
                                ),
                            ])

                            return {
                                name: docSnap?.data()?.title ?? item.name,
                                metadata,
                                url,
                            }
                        })
                    )
                )
                .then((videos) => {
                    if (!videos.length) this.$router.push("/welcome")
                    this.videos = videos.sort(
                        (a, b) =>
                            Date.parse(b.metadata.updated) -
                            Date.parse(a.metadata.updated)
                    )
                })
        },
        login() {
            if (this.loggedIn) signOut(auth)
            else {
                signInWithPopup(auth, new GoogleAuthProvider()).catch(() => {})
            }
        },
    },
}
</script>
<style lang="scss">
.body {
    max-width: 1400px;
    width: 100%;

    padding: 36px;
    margin: 0 auto;

    @media (max-width: 750px) {
        padding-left: 16px;
        padding-right: 16px;
    }

    ._header {
        font-weight: 600;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        height: 52px;
    }
}

.videos {
    .video_item {
        margin: 8px 0;
        padding: 8px 0;
        font-size: 15px;
        box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
        text-decoration: none;
        display: block;
        color: inherit;
        .title {
            color: white;
            font-weight: 500;
        }
        &:hover {
            .title {
                color: #1a5cff;
            }
        }
        .modified {
            margin-top: 8px;
        }
        .meta {
            display: flex;
            font-size: 13px;
            .size {
                padding-right: 6px;
            }
        }
    }
}
.loading {
    padding: 90px 36px;
    text-align: center;
}
</style>
