/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { $snackBars } from '@/services/snackBars'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated(registration) {
            $snackBars.set('refresh pwa', {
                message: 'New version available',
                action: {
                    label: 'Update',
                    fn: () => {
                        $snackBars.delete('refresh pwa')

                        // Make sure we only send a 'skip waiting' message if the SW is waiting
                        if (!registration || !registration.waiting) return
                        // send message to SW to skip the waiting and activate the new SW
                        registration.waiting.postMessage({
                            type: 'SKIP_WAITING'
                        })
                    }
                }
            })
        },
        offline() {
            console.log(
                'No internet connection found. App is running in offline mode.'
            )
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
