<template>
    <site-banners />
    <router-view />
    <wSnackbars />
</template>
<script>
import wSnackbars from "@/components/wSnackbars"
import SiteBanners from "@/components/SiteBanners"
let refreshing
export default {
    components: {
        wSnackbars,
        SiteBanners,
    },
    created() {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (refreshing) return
                refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
    },
}
</script>

<style lang="scss">
* {
    margin: 0;
    box-sizing: border-box;
}
html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background: #101114;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px;
}
</style>
