import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "Welcome" */ '../views/Welcome.vue')
    },
    {
        path: '/record',
        name: 'Record',
        component: () => import(/* webpackChunkName: "Record" */ '../views/Record.vue')
    },
    {
        path: '/preview',
        name: 'Preview',
        component: () => import(/* webpackChunkName: "Preview" */ '../views/Preview.vue')
    },
    {
        path: '/videos',
        name: 'Videos',
        component: () => import(/* webpackChunkName: "Videos" */ '../views/Videos.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
