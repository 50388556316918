<template>
    <div class="snack-bars" v-bind="$attrs">
        <transition-group name="slide-fade">
            <div
                v-for="[key, { message, action, close }] in $snackBars"
                class="notification"
                :key="key"
            >
                <div class="message" v-html="message"></div>
                <w-button
                    v-if="action?.label"
                    color="#ffffff"
                    background="rgb(92, 95, 98)"
                    @click="action.fn"
                >{{ action.label }}</w-button>
                <w-close
                    v-if="close || !action"
                    @click="$snackBars.delete(key)"
                />
            </div>
        </transition-group>
    </div>
</template>
<script>
import wButton from '@/components/wButton'
import wClose from '@/components/wClose'
export default {
    components: {
        wButton,
        wClose
    }
}
</script>
<style lang="scss" scoped>
.snack-bars {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    pointer-events: none;

    .notification {
        pointer-events: auto;
        bottom: 0px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: #e5e6e7;
        background: #323437;
        box-shadow: rgba(0, 0, 0, .5) 0px 3px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 4px 4px 0px;
        position: relative;
        min-height: 56px;
        max-width: #{"min(700px, calc(100% - 36px))"};
        padding: .5em 1em;
        margin-top: 1em;
        width: auto;
        // color: #323232;
        color: white;
        border-radius: 4px;
        overflow: hidden;
        .message {
            overflow: auto;
            max-height: 100%;
        }

        .button {
            white-space: nowrap;
            // box-shadow: inset 0 0 1px #88caff;
            height: 36px;
            font-size: 16px;
            &:not(:empty) {
                margin-left: 8px;
            }
        }
    }
}

.close {
    flex-shrink: 0;
    margin-right: -12px;
}
</style>

<style lang="scss" scoped>
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: scale(.85);
        opacity: 0;
    }

    .slide-move {
        transition: transform 0.8s ease;
    }
</style>
