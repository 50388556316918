<template>
    <header>
        <div class="left">
            <w-button icon background="transparent" to="/">
                <img src="@/assets/logo-white.svg" width="36" alt="" />
            </w-button>
        </div>
        <div class="right">
            <w-button background="#202124" to="/record">
                <icon :icon="icons.roundVideoCall" height="26" />
                Record new video
            </w-button>
            <w-button
                background="#202124"
                to="https://forms.gle/aUNu9Q9Lxam1TFgF7"
                target="_blank"
            >
                <icon :icon="icons.roundMessage" height="20" />
                Give feedback
            </w-button>
            <w-button v-if="currentUser" @click="signout" background="#202124">
                <icon :icon="icons.sharpAccountCircle" height="20" />
                Log out
            </w-button>
            <w-button v-else @click="signin" background="#202124">
                <icon :icon="icons.sharpAccountCircle" height="20" />
                Sign in
            </w-button>
        </div>
    </header>
</template>

<script>
import wButton from "@/components/wButton"
import { Icon } from "@iconify/vue"
import roundArrowBackIosNew from "@iconify-icons/ic/round-arrow-back-ios-new"
import roundHelpOutline from "@iconify-icons/ic/round-help-outline"
import cloudUpload from "@iconify-icons/ic/baseline-cloud-upload"
import baselinePlaylistAdd from "@iconify-icons/ic/baseline-playlist-add"
import downloadForOffline from "@iconify-icons/ic/baseline-download"
import sharpAccountCircle from "@iconify-icons/ic/sharp-account-circle"
import baselineLogin from "@iconify-icons/ic/baseline-login"
import roundVideoCall from "@iconify-icons/ic/round-video-call"
import roundMessage from "@iconify-icons/ic/round-message"

import {
    getAuth,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
} from "firebase/auth"

export default {
    name: "AppNav",
    components: {
        wButton,
        Icon,
    },
    data() {
        return {
            icons: {
                roundArrowBackIosNew,
                roundHelpOutline,
                cloudUpload,
                downloadForOffline,
                baselinePlaylistAdd,
                baselineLogin,
                roundMessage,
                roundVideoCall,
                sharpAccountCircle,
            },
            currentUser: null,
        }
    },
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            this.currentUser = user ?? false
        })
    },
    methods: {
        signin(resolve) {
            signInWithPopup(getAuth(), new GoogleAuthProvider())
                .catch(() => {})
                .then(resolve)
        },
        signout(resolve) {
            signOut(getAuth())
                .catch(() => {})
                .then(resolve)
        },
    },
}
</script>

<style lang="scss" scoped>
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 0 16px;
    background: #202124;
    .right {
        display: flex;
        align-items: center;
        .button {
            font-size: 16px;
            margin-left: 8px;
            &:not(.icon) {
                padding: 16px;
            }

            svg {
                margin-right: 4px;
            }
        }
    }
}
</style>
