<template>
    <div class="home">
        <app-nav />
        <videos></videos>
    </div>
</template>

<script>
import Videos from "@/views/Videos"
import AppNav from "../components/AppNav.vue"

import { getAuth, onAuthStateChanged } from "firebase/auth"

export default {
    name: "Home",
    components: {
        Videos,
        AppNav,
    },
    mounted() {
        onAuthStateChanged(getAuth(), (user) => {
            if (!user) this.$router.push("/welcome")
        })
    },
}
</script>

<style lang="scss" scoped>
.home {
    -webkit-font-smoothing: antialiased;
    background: #101114;
    font-size: 15px;
    margin: 0;
    overflow: hidden;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        padding: 0 16px;
        background: #202124;
        .right {
            display: flex;
            align-items: center;
            .button {
                font-size: 16px;
                // margin-left: 16px;
            }
        }
    }

    .preview {
        display: flex;
        justify-content: center;
        padding: 32px 16px;
        @media only screen and (max-width: 880px) {
            flex-direction: column;
        }

        .preview-wrapper {
            display: flex;
            flex: 1;
            padding: 0 12px;
            video {
                width: 100%;
                max-height: calc(100vh - 100px);
            }
        }
        aside {
            min-width: 360px;
            padding: 0 12px;

            @media only screen and (max-width: 880px) {
                min-width: auto;
                padding: 36px 12px;
            }
            .form-element {
                padding-bottom: 24px;

                .label {
                    font-weight: bold;
                    padding-bottom: 4px;
                }

                input[type="text"] {
                    width: 100%;
                    height: 44px;
                    padding: 0 8px;
                    border-radius: 6px;
                    background: #161616;
                    border: 1px solid #606164;
                    color: white;
                    font-size: 16px;
                    &:not(:focus) {
                        opacity: 0.6;
                    }
                }
            }

            .button {
                width: 100%;
                height: 40px;
                margin-top: 16px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 6px;
                gap: 12px;
                padding: 0 16px;
                font-size: 14px;
                font-weight: 600;
                background: #2b2e31;
                text-transform: uppercase;
                // flex-direction: row-reverse;
                .status {
                    flex: 1;
                    text-align: right;
                }
            }
        }
    }
}

.back-button {
    padding-left: 4px;
}
</style>
