<template>
    <div class="banners">
        <div v-if="page === '/record' && !isChrome" class="banner">
            <h2>Browser not supported</h2>
            <p>Please use Chrome for <b>Desktop</b> for best experience</p>
            <w-button to="https://www.google.com/chrome/">
                Get Chrome for Desktop
            </w-button>
        </div>
    </div>
</template>

<script>
import wButton from "@/components/wButton"
export default {
    components: {
        wButton,
    },
    computed: {
        isChrome() {
            return (
                /Chrome/.test(navigator.userAgent) &&
                /Google Inc/.test(navigator.vendor)
            )
        },
        page() {
            return this.$route.path
        },
    },
}
</script>

<style lang="scss" scoped>
.banners {
    display: flex;
    justify-content: center;
    .banner {
        text-align: center;
        position: absolute;
        margin-top: 80px;
        background: white;
        padding: 36px;
        z-index: 1;
        border-radius: 12px;
        p {
            padding: 12px 0;
        }
        .button {
            margin-top: 16px;
            padding: 16px 36px;
            font-size: 16px;
        }
    }
}
</style>